@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Lexend:wght@100..900&display=swap');
@import "./Form.css";
@import "./variables.css";
@import "./TransitionWrapper.css";
@import "./Buttons.css";
@import "./Pages.css";
@import "./Graphic.css";


:root {
  font-family: 'Lexend', sans-serif;
}

html {
  font-size: 16px;
}

body {
  margin: 0;
  font-family: var(--font-family-base);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--color-black);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  color: inherit;
  text-decoration: none;
}

.edit-link {
  color: var(--color-theme1);
  text-decoration: underline;
  font-weight: 700;
  font-size: 1.5rem;
}

.cancel-link {
  color: var(--color-red);
  text-decoration: underline;
  font-weight: 700;
  font-size: 1.5rem;
}

.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  /* width: auto; */
  /* min-width: 400px; */
  position: relative;
  margin: 0 auto;
  padding: 0;
  box-sizing: border-box;

}

.main {
  flex-grow: 1; /* makes it grow the HEIGHT of the page so the footer is at the bottom */
  align-self: center;
  width: 100%;
  max-width: 1200px;
}

section {
  padding: 10px;
}

h1 {
  font-size: 2.0rem;
  font-weight: 900;
  margin: 0px 0px 10px 0px;
}

h2 {
  font-size: 1.5rem;
  margin: 0;
}

h3 {
  font-size: 1.2rem;
  margin: 0;
}
h4 {
  font-size: 1rem;
  margin: 0;
}

p {
  margin: 0px 0px 15px 0px;
}


@media only screen and (min-width: 740px) {


}

/* DESKTOP BREAK SIZE */
@media only screen and (min-width: 1024px) {

  h1 {
    font-size: 3.0rem;
  }

  h2 {
    font-size: 2.25rem;

  }
  
  h3 {
    font-size: 1.8rem;

  }
  h4 {
    font-size: 1.5rem;

  }
  
  p {
    font-size: 1.2rem;

  }

  

}