/* Hamburger.css */
.hamburger {
    cursor: pointer;
    width: 35px;
    height: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.bar {
    width: 100%;
    height: 6px;
    background-color: black;
    transition: all 0.2s ease-in-out;
}

.change .bar:nth-child(1) {
    transform: translateY(12px) rotate(45deg);
}

.change .bar:nth-child(2) {
    opacity: 0;
}

.change .bar:nth-child(3) {
    transform: translateY(-12px) rotate(-45deg);
}
