/* Modal.css */
/* .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 3, 9, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    overflow-y: auto;
    
}
  
.modal-content {
    background: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    max-width: 400px;
    height: auto;
    margin: 10px 10px 10px 10px;
    padding: 30px 20px 10px 20px;
    border-radius: 10px;
    position: relative;
    z-index: 1001;
} */

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 3, 9, 0.7);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  z-index: 1000;
  padding: 20px;
  overflow-y: auto;
  box-sizing: border-box;
}

.modal-content {
  background: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  padding: 20px 10px;
  border-radius: 10px;
  position: relative;
  z-index: 1001;
  max-height: calc(100vh - 40px);
  overflow-y: auto;
  box-sizing: border-box;
}
  
.close-button {
  align-self: flex-start;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;
  position: relative;
  padding-bottom: 30px;
}

.close-button .bar {
  width: 40px;
  height: 4px;
  background-color: black;
  position: absolute;

}

.close-button .bar:nth-child(1) {
  transform: rotate(45deg);
}

.close-button .bar:nth-child(2) {
  transform: rotate(-45deg);
}

.small-link {
  text-decoration: underline;
  color: var(--color-theme1);
  cursor: pointer;
  font-weight: 500;
  padding: 20px 0px;

}

.success-popup {
  background-color: var(--color-theme1);
  color: #ffffff;
  width: auto;
  height: auto;
  padding: 50px 10px;
  align-self: center;
  border-radius: var(--border-radius);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.confirm-popup {
  background-color: rgb(236, 236, 236);
  color: var(--color-black);
  padding: 60px 30px;
  margin-top: 20px;
  width: auto;
  height: auto;
  align-self: center;
  border-radius: var(--border-radius);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.confirm-popup h3 {
  text-align: center;
  margin-bottom: 30px;
}

.success-popup h3 {
  text-align: center;
  margin-bottom: 30px;
  margin-top: 50px;
}

.fail-popup {
  background-color: #ffffff;
  color: var(--color-red);
  width: auto;
  height: 150px;
  align-self: center;
  border-radius: var(--border-radius);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
