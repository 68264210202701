.nav-menu {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    position: absolute;
    top: 60px;
    z-index: 1;
    background-color: #b4b4b4;
    max-height: 0;
    overflow: hidden;
    opacity: 1.0;
    transition: all 0.3s ease-in-out;
}

.nav-menu.open {
    opacity: 1.0;
    max-height: 100%;
}

#nav-links {
    display: flex;
    flex-direction: column; /* Stack links vertically by default */
    padding: 10px 20px;
}

.nav-menu a {
    display: block;
    padding: 10px 0;
    text-decoration: none;
    font-weight: 700;
    text-align: right; /* Center text in the links */
}

.nav-menu a:hover {
    color: var(--link-hover);
}

/* DESKTOP BREAK SIZE */
@media only screen and (min-width: 1024px) {
    .nav-menu {
        top: 100px;
        justify-content: center; /* Center #nav-links horizontally */
        align-items: center; /* Center #nav-links vertically */
    }

    #nav-links {
        flex-direction: row; /* Display links horizontally on desktop */
        justify-content: space-between;
        width: 100%;
        max-width: 1200px;
        padding: 20px 40px;
    }

    .nav-menu a {
        text-align: center; /* Keep text centered in the links */
    }
}
