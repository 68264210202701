:root {
    --font-family-base: 'Lexend', sans-serif;
    --color-theme1: #00b2af;
    --color-theme1-dark: #005b71;
    --color-theme2: #ff044d;
    --color-theme2-dark: #be013a;
    --color-theme3: #ff8201;
    --color-theme3-dark: #fc4600;
    --color-red: var(--color-theme2-dark);
    --color-grey1: #d9d9d9;
    --color-grey2: #636363;
    --color-black: #010e13;
    --border-style: solid;
    --border-color: var(--color-grey1);
    --button-border-color: var(--color-theme1-dark);
    --border-radius: 15px;
    --border-width: 2px;
    --border: var(--border-width) var(--border-style) var(--border-color) ;
    --footer-color: var(--color-black);
    --link-hover: #fff;

}
